//import logo from './logo.svg';
import './App.css';
import { Authenticator,  View,Image , useTheme, Text } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import CSVSeeder from './CSVSeeder.js';
import Home from './Home';
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";


const formFields = {
  signUp: {
    email: {
      order: 1
    },
    given_name: {
      order: 2
    },
    family_name: {
      order: 3
    },
    password: {
      order: 4
    },
    confirm_password: {
      order: 5
    }
  },
}

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="SMART | UOW"
          src="https://www.uow.edu.au/assets/contributed/faculty-of-engineering-and-information-sciences/smart/SMART-partnered-hybrid---blue-black.svg"
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  }
}

function App() {
  return (
    <BrowserRouter>
      <Authenticator formFields={formFields} components={components}>
        {({ signOut, user }) => {
          console.log(user);
          return (<>
            <Routes>
              <Route>
                <Route path="/" element={<Home  signOut={signOut} user={user}  />} />

                <Route path="/admin" element={<CSVSeeder />} />

                <Route path='*' element={<h1>404 Not Found!</h1>} />
              </Route>
            </Routes>
            {/* <header className="App-header">
              <p>
                Logged in as {user.attributes.given_name} {user.attributes.family_name} ({user.attributes.email})
              </p>
              <Button onClick={signOut}>Sign out</Button>
            </header> */}
          </>
          )
        }}

      </Authenticator>
    </BrowserRouter>
  );
}

export default App;
