/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getResource = /* GraphQL */ `
  query GetResource($id: ID!) {
    getResource(id: $id) {
      id
      litrev_id
      type {
        id
        name
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      rating
      author
      authoryear
      title
      url
      object_key
      abstract
      country
      theme
      resilience_stage {
        id
        title
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments
      research_question_issue
      findings
      recommendations
      flooding_events_types
      potential_solution_extent_applied
      railway_adapt
      contemporary_challenges
      flooding_model_info
      future_trends_planning
      causal_factors
      tags {
        items {
          id
          resourceId
          tagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      resilienceStageResourcesId
      resourceTypeResourcesId
    }
  }
`;
export const listResources = /* GraphQL */ `
  query ListResources(
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        litrev_id
        type {
          id
          name
          createdAt
          updatedAt
        }
        rating
        author
        authoryear
        title
        url
        object_key
        abstract
        country
        theme
        resilience_stage {
          id
          title
          createdAt
          updatedAt
        }
        comments
        research_question_issue
        findings
        recommendations
        flooding_events_types
        potential_solution_extent_applied
        railway_adapt
        contemporary_challenges
        flooding_model_info
        future_trends_planning
        causal_factors
        tags {
          nextToken
        }
        createdAt
        updatedAt
        resilienceStageResourcesId
        resourceTypeResourcesId
      }
      nextToken
    }
  }
`;
export const getResilienceStage = /* GraphQL */ `
  query GetResilienceStage($id: ID!) {
    getResilienceStage(id: $id) {
      id
      title
      resources {
        items {
          id
          litrev_id
          rating
          author
          authoryear
          title
          url
          object_key
          abstract
          country
          theme
          comments
          research_question_issue
          findings
          recommendations
          flooding_events_types
          potential_solution_extent_applied
          railway_adapt
          contemporary_challenges
          flooding_model_info
          future_trends_planning
          causal_factors
          createdAt
          updatedAt
          resilienceStageResourcesId
          resourceTypeResourcesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listResilienceStages = /* GraphQL */ `
  query ListResilienceStages(
    $filter: ModelResilienceStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResilienceStages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResourceType = /* GraphQL */ `
  query GetResourceType($id: ID!) {
    getResourceType(id: $id) {
      id
      name
      resources {
        items {
          id
          litrev_id
          rating
          author
          authoryear
          title
          url
          object_key
          abstract
          country
          theme
          comments
          research_question_issue
          findings
          recommendations
          flooding_events_types
          potential_solution_extent_applied
          railway_adapt
          contemporary_challenges
          flooding_model_info
          future_trends_planning
          causal_factors
          createdAt
          updatedAt
          resilienceStageResourcesId
          resourceTypeResourcesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listResourceTypes = /* GraphQL */ `
  query ListResourceTypes(
    $filter: ModelResourceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResourceTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      label
      resources {
        items {
          id
          resourceId
          tagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResourceTags = /* GraphQL */ `
  query GetResourceTags($id: ID!) {
    getResourceTags(id: $id) {
      id
      resourceId
      tagId
      resource {
        id
        litrev_id
        type {
          id
          name
          createdAt
          updatedAt
        }
        rating
        author
        authoryear
        title
        url
        object_key
        abstract
        country
        theme
        resilience_stage {
          id
          title
          createdAt
          updatedAt
        }
        comments
        research_question_issue
        findings
        recommendations
        flooding_events_types
        potential_solution_extent_applied
        railway_adapt
        contemporary_challenges
        flooding_model_info
        future_trends_planning
        causal_factors
        tags {
          nextToken
        }
        createdAt
        updatedAt
        resilienceStageResourcesId
        resourceTypeResourcesId
      }
      tag {
        id
        label
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listResourceTags = /* GraphQL */ `
  query ListResourceTags(
    $filter: ModelResourceTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResourceTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        resourceId
        tagId
        resource {
          id
          litrev_id
          rating
          author
          authoryear
          title
          url
          object_key
          abstract
          country
          theme
          comments
          research_question_issue
          findings
          recommendations
          flooding_events_types
          potential_solution_extent_applied
          railway_adapt
          contemporary_challenges
          flooding_model_info
          future_trends_planning
          causal_factors
          createdAt
          updatedAt
          resilienceStageResourcesId
          resourceTypeResourcesId
        }
        tag {
          id
          label
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const resourceTagsByResourceId = /* GraphQL */ `
  query ResourceTagsByResourceId(
    $resourceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResourceTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceTagsByResourceId(
      resourceId: $resourceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resourceId
        tagId
        resource {
          id
          litrev_id
          rating
          author
          authoryear
          title
          url
          object_key
          abstract
          country
          theme
          comments
          research_question_issue
          findings
          recommendations
          flooding_events_types
          potential_solution_extent_applied
          railway_adapt
          contemporary_challenges
          flooding_model_info
          future_trends_planning
          causal_factors
          createdAt
          updatedAt
          resilienceStageResourcesId
          resourceTypeResourcesId
        }
        tag {
          id
          label
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const resourceTagsByTagId = /* GraphQL */ `
  query ResourceTagsByTagId(
    $tagId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResourceTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceTagsByTagId(
      tagId: $tagId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resourceId
        tagId
        resource {
          id
          litrev_id
          rating
          author
          authoryear
          title
          url
          object_key
          abstract
          country
          theme
          comments
          research_question_issue
          findings
          recommendations
          flooding_events_types
          potential_solution_extent_applied
          railway_adapt
          contemporary_challenges
          flooding_model_info
          future_trends_planning
          causal_factors
          createdAt
          updatedAt
          resilienceStageResourcesId
          resourceTypeResourcesId
        }
        tag {
          id
          label
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
