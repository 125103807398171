import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import * as mutations from './graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton } from "@mui/material";
import { Predictions } from 'aws-amplify';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const filter = createFilterOptions();

export default function TagSelector({currentTags, setCurrentTags, allTags, setAllTags, naturalText}) {
  const [open, toggleOpen] = React.useState(false);
  const [newTagLabel, setNewTagLabel] = React.useState('');
  const [allKeyPhrases, setAllKeyPhrases] = React.useState([]);
  const [allEntities, setAllEntities] = React.useState([]);
  const [selectedKeyPhrase, setSelectedKeyPhrase] = React.useState('');
  const [selectedEntity, setSelectedEntity] = React.useState('');
  
    



  const handleClose = () => {
    setNewTagLabel('');
    toggleOpen(false);
  };

  const handleKpChange = (event) => {
    setSelectedKeyPhrase(event.target.value);
    setNewTagLabel(event.target.value);
  };

  const handleEntChange = (event) => {
    setSelectedEntity(event.target.value);
    setNewTagLabel(event.target.value);
  };

  const handleNLP = React.useCallback(async () => {
    try {
      const result = await Predictions.interpret({
          text: {
            source: {
              text: naturalText,
            },
            type: "ALL"
          }
        });
      setAllKeyPhrases(result.textInterpretation.keyPhrases);
      setAllEntities(result.textInterpretation.textEntities);
      console.log(result);
  }
  catch (err) {
      console.log(err);
  }
  },[naturalText]);



  const handleSubmit = React.useCallback(async () => {
    const result = await API.graphql(graphqlOperation(mutations.createTag, {
        input: {
            label: newTagLabel
        }
    }));
    console.log(result);
    const createdTag = result.data.createTag;
    setAllTags((currentAllTags) => (currentAllTags.concat([createdTag])));
    setCurrentTags((curTags) => ([...curTags, createdTag]));
   

    handleClose();
  },[newTagLabel,setCurrentTags, setAllTags]);

  const handleTagDelete = React.useCallback(async (tagId) => {
    console.log('deleting ', tagId )
    const result = await API.graphql(graphqlOperation(mutations.deleteTag, {
        input: {
            id: tagId
        }
    }));
    console.log(result);
    setAllTags((currentAllTags) => (currentAllTags.filter((tg) =>(tg.id !== result.data.deleteTag.id))));
  },[setAllTags]);

  

  return (
    
    <React.Fragment>
      <Autocomplete
        value={currentTags}
        style={{ marginTop: '1rem' }}
        multiple
        onChange={(event, newValue,reason,details) => {
           console.log(newValue,reason,details);
          if (newValue?.at(-1)?.inputValue !== undefined) {
            toggleOpen(true);
            setNewTagLabel(newValue.at(-1).inputValue);
          } else {
            setCurrentTags(newValue);
          }
        }}
        filterOptions={(options, params) => {
          //console.log(options,params);
          const filtered = filter(options, params);
          //console.log(filtered);

          // if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              label: 'Modify Tags ...'//`Add "${params.inputValue}"`
            });
          //}

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={allTags}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        // sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Resource Tags" />
        )}
      />
      <Dialog open={open} onClose={handleClose} maxWidth='md'>
        <DialogTitle>Create a new Tag</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item container xs={8} flexDirection='column'>
              <DialogContentText>
                You may use using Natural Language Processing (NLP), to detect key phrases and entites.
              </DialogContentText>

              <Button color='info' variant='outlined' onClick={handleNLP}>{`Analyse Abstract`}</Button>


             {allKeyPhrases.length >0 && <FormControl fullWidth style={{marginTop:'1rem'}}>
                <InputLabel id="keyphrases-label">Key Phrases</InputLabel>
                <Select
                  labelId="keyphrases-label"
                  id="keyphrases"
                  value={selectedKeyPhrase}
                  label="Key phrases"
                  onChange={handleKpChange}
                >
                  <MenuItem value={''} selected disabled>Select a Key Phrase</MenuItem>
                  {allKeyPhrases.map((kp)=> (<MenuItem value={kp.text}>{kp.text}</MenuItem>))}
                </Select>
              </FormControl>}

              {allEntities.length >0 && <FormControl fullWidth style={{marginTop:'1rem'}}>
                <InputLabel id="entities-label">Detected Entities</InputLabel>
                <Select
                  labelId="entities-label"
                  id="entities"
                  value={selectedEntity}
                  label="Detected Entities"
                  onChange={handleEntChange}
                >
                  <MenuItem value={''} selected disabled>Select an entity</MenuItem>
                  {allEntities.map((ent)=> (<MenuItem value={ent.text}>{ent.text + ' ('+ent.type+')'}</MenuItem>))}
                </Select>
              </FormControl>}

              <TextField
                autoFocus
                margin="dense"
                id="name"
                value={newTagLabel}
                onChange={(event) =>
                  setNewTagLabel(event.target.value)
                }
                label="New Tag Label"
                type="text"
                variant="standard"
              />
              <Button color='success' variant='outlined' onClick={handleSubmit}>{`Add`}</Button>
            </Grid>
            <Grid item xs={4}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{fontWeight: 'bold'}}>Tag</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allTags.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.label}
                        </TableCell>
                        <TableCell align="right"><IconButton onClick={()=>handleTagDelete(row.id)}> <DeleteForeverIcon color='error'/></IconButton></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}