/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createResource = /* GraphQL */ `
  mutation CreateResource(
    $input: CreateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    createResource(input: $input, condition: $condition) {
      id
      litrev_id
      type {
        id
        name
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      rating
      author
      authoryear
      title
      url
      object_key
      abstract
      country
      theme
      resilience_stage {
        id
        title
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments
      research_question_issue
      findings
      recommendations
      flooding_events_types
      potential_solution_extent_applied
      railway_adapt
      contemporary_challenges
      flooding_model_info
      future_trends_planning
      causal_factors
      tags {
        items {
          id
          resourceId
          tagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      resilienceStageResourcesId
      resourceTypeResourcesId
    }
  }
`;
export const updateResource = /* GraphQL */ `
  mutation UpdateResource(
    $input: UpdateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    updateResource(input: $input, condition: $condition) {
      id
      litrev_id
      type {
        id
        name
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      rating
      author
      authoryear
      title
      url
      object_key
      abstract
      country
      theme
      resilience_stage {
        id
        title
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments
      research_question_issue
      findings
      recommendations
      flooding_events_types
      potential_solution_extent_applied
      railway_adapt
      contemporary_challenges
      flooding_model_info
      future_trends_planning
      causal_factors
      tags {
        items {
          id
          resourceId
          tagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      resilienceStageResourcesId
      resourceTypeResourcesId
    }
  }
`;
export const deleteResource = /* GraphQL */ `
  mutation DeleteResource(
    $input: DeleteResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    deleteResource(input: $input, condition: $condition) {
      id
      litrev_id
      type {
        id
        name
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      rating
      author
      authoryear
      title
      url
      object_key
      abstract
      country
      theme
      resilience_stage {
        id
        title
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments
      research_question_issue
      findings
      recommendations
      flooding_events_types
      potential_solution_extent_applied
      railway_adapt
      contemporary_challenges
      flooding_model_info
      future_trends_planning
      causal_factors
      tags {
        items {
          id
          resourceId
          tagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      resilienceStageResourcesId
      resourceTypeResourcesId
    }
  }
`;
export const createResilienceStage = /* GraphQL */ `
  mutation CreateResilienceStage(
    $input: CreateResilienceStageInput!
    $condition: ModelResilienceStageConditionInput
  ) {
    createResilienceStage(input: $input, condition: $condition) {
      id
      title
      resources {
        items {
          id
          litrev_id
          rating
          author
          authoryear
          title
          url
          object_key
          abstract
          country
          theme
          comments
          research_question_issue
          findings
          recommendations
          flooding_events_types
          potential_solution_extent_applied
          railway_adapt
          contemporary_challenges
          flooding_model_info
          future_trends_planning
          causal_factors
          createdAt
          updatedAt
          resilienceStageResourcesId
          resourceTypeResourcesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateResilienceStage = /* GraphQL */ `
  mutation UpdateResilienceStage(
    $input: UpdateResilienceStageInput!
    $condition: ModelResilienceStageConditionInput
  ) {
    updateResilienceStage(input: $input, condition: $condition) {
      id
      title
      resources {
        items {
          id
          litrev_id
          rating
          author
          authoryear
          title
          url
          object_key
          abstract
          country
          theme
          comments
          research_question_issue
          findings
          recommendations
          flooding_events_types
          potential_solution_extent_applied
          railway_adapt
          contemporary_challenges
          flooding_model_info
          future_trends_planning
          causal_factors
          createdAt
          updatedAt
          resilienceStageResourcesId
          resourceTypeResourcesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteResilienceStage = /* GraphQL */ `
  mutation DeleteResilienceStage(
    $input: DeleteResilienceStageInput!
    $condition: ModelResilienceStageConditionInput
  ) {
    deleteResilienceStage(input: $input, condition: $condition) {
      id
      title
      resources {
        items {
          id
          litrev_id
          rating
          author
          authoryear
          title
          url
          object_key
          abstract
          country
          theme
          comments
          research_question_issue
          findings
          recommendations
          flooding_events_types
          potential_solution_extent_applied
          railway_adapt
          contemporary_challenges
          flooding_model_info
          future_trends_planning
          causal_factors
          createdAt
          updatedAt
          resilienceStageResourcesId
          resourceTypeResourcesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createResourceType = /* GraphQL */ `
  mutation CreateResourceType(
    $input: CreateResourceTypeInput!
    $condition: ModelResourceTypeConditionInput
  ) {
    createResourceType(input: $input, condition: $condition) {
      id
      name
      resources {
        items {
          id
          litrev_id
          rating
          author
          authoryear
          title
          url
          object_key
          abstract
          country
          theme
          comments
          research_question_issue
          findings
          recommendations
          flooding_events_types
          potential_solution_extent_applied
          railway_adapt
          contemporary_challenges
          flooding_model_info
          future_trends_planning
          causal_factors
          createdAt
          updatedAt
          resilienceStageResourcesId
          resourceTypeResourcesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateResourceType = /* GraphQL */ `
  mutation UpdateResourceType(
    $input: UpdateResourceTypeInput!
    $condition: ModelResourceTypeConditionInput
  ) {
    updateResourceType(input: $input, condition: $condition) {
      id
      name
      resources {
        items {
          id
          litrev_id
          rating
          author
          authoryear
          title
          url
          object_key
          abstract
          country
          theme
          comments
          research_question_issue
          findings
          recommendations
          flooding_events_types
          potential_solution_extent_applied
          railway_adapt
          contemporary_challenges
          flooding_model_info
          future_trends_planning
          causal_factors
          createdAt
          updatedAt
          resilienceStageResourcesId
          resourceTypeResourcesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteResourceType = /* GraphQL */ `
  mutation DeleteResourceType(
    $input: DeleteResourceTypeInput!
    $condition: ModelResourceTypeConditionInput
  ) {
    deleteResourceType(input: $input, condition: $condition) {
      id
      name
      resources {
        items {
          id
          litrev_id
          rating
          author
          authoryear
          title
          url
          object_key
          abstract
          country
          theme
          comments
          research_question_issue
          findings
          recommendations
          flooding_events_types
          potential_solution_extent_applied
          railway_adapt
          contemporary_challenges
          flooding_model_info
          future_trends_planning
          causal_factors
          createdAt
          updatedAt
          resilienceStageResourcesId
          resourceTypeResourcesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      label
      resources {
        items {
          id
          resourceId
          tagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      label
      resources {
        items {
          id
          resourceId
          tagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      label
      resources {
        items {
          id
          resourceId
          tagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createResourceTags = /* GraphQL */ `
  mutation CreateResourceTags(
    $input: CreateResourceTagsInput!
    $condition: ModelResourceTagsConditionInput
  ) {
    createResourceTags(input: $input, condition: $condition) {
      id
      resourceId
      tagId
      resource {
        id
        litrev_id
        type {
          id
          name
          createdAt
          updatedAt
        }
        rating
        author
        authoryear
        title
        url
        object_key
        abstract
        country
        theme
        resilience_stage {
          id
          title
          createdAt
          updatedAt
        }
        comments
        research_question_issue
        findings
        recommendations
        flooding_events_types
        potential_solution_extent_applied
        railway_adapt
        contemporary_challenges
        flooding_model_info
        future_trends_planning
        causal_factors
        tags {
          nextToken
        }
        createdAt
        updatedAt
        resilienceStageResourcesId
        resourceTypeResourcesId
      }
      tag {
        id
        label
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateResourceTags = /* GraphQL */ `
  mutation UpdateResourceTags(
    $input: UpdateResourceTagsInput!
    $condition: ModelResourceTagsConditionInput
  ) {
    updateResourceTags(input: $input, condition: $condition) {
      id
      resourceId
      tagId
      resource {
        id
        litrev_id
        type {
          id
          name
          createdAt
          updatedAt
        }
        rating
        author
        authoryear
        title
        url
        object_key
        abstract
        country
        theme
        resilience_stage {
          id
          title
          createdAt
          updatedAt
        }
        comments
        research_question_issue
        findings
        recommendations
        flooding_events_types
        potential_solution_extent_applied
        railway_adapt
        contemporary_challenges
        flooding_model_info
        future_trends_planning
        causal_factors
        tags {
          nextToken
        }
        createdAt
        updatedAt
        resilienceStageResourcesId
        resourceTypeResourcesId
      }
      tag {
        id
        label
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteResourceTags = /* GraphQL */ `
  mutation DeleteResourceTags(
    $input: DeleteResourceTagsInput!
    $condition: ModelResourceTagsConditionInput
  ) {
    deleteResourceTags(input: $input, condition: $condition) {
      id
      resourceId
      tagId
      resource {
        id
        litrev_id
        type {
          id
          name
          createdAt
          updatedAt
        }
        rating
        author
        authoryear
        title
        url
        object_key
        abstract
        country
        theme
        resilience_stage {
          id
          title
          createdAt
          updatedAt
        }
        comments
        research_question_issue
        findings
        recommendations
        flooding_events_types
        potential_solution_extent_applied
        railway_adapt
        contemporary_challenges
        flooding_model_info
        future_trends_planning
        causal_factors
        tags {
          nextToken
        }
        createdAt
        updatedAt
        resilienceStageResourcesId
        resourceTypeResourcesId
      }
      tag {
        id
        label
        resources {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
