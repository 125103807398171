//import { FileUploader } from '@aws-amplify/ui-react';
import {csvParse} from 'd3-dsv';
//import * as d3c from 'd3-collection';
import * as mutations from './graphql/mutations';
import * as quereis from './graphql/queries';
import {Button} from '@aws-amplify/ui-react';
import {useCallback} from 'react';
import {API, graphqlOperation} from 'aws-amplify';





// const greyFields = {
//   "ID": "litrev_id",
//   "Rating": "rating",
//   "Author": "author",
//   "Year": "authoryear",
//   "Title": "title",
//   "Link": "url",
//   "Country": "country",
//   "Resilience Stage": "resilience_stage",
//   "Comments": "comments",
//   "What types of flooding events are described. Describe case studies,  location, date": "flooding_events_types",
//   "Potential solution? And to what context was it applied?": "potential_solution_extent_applied",
//   "What do we know about how railways adapt to flooding events?": "railway_adapt",
//   "Contemporary challenges (re. flooding) that are listed": "contemporary_challenges",
//   "What flooding models exist? If so what type and what does the model do/is it for?": "flooding_model_info",
//   "Future trends or planning for flooding events": "future_trends_planning",
//   "Causal Factors (\"effect\", \"affect\")": "causal_factors"
// }

let allRecords=[];

async function onChange(e) {
  const file = e.target.files[0];
  try {

    // await Storage.put(file.name, file, {
    //   contentType: "image/png", // contentType is optional
    // });

    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      allRecords = csvParse(event.target.result);
      console.log(allRecords);
      // console.log(JSON.stringify(allRecords[0],null,2));
      // const mapped = d3c.map(allRecords, (e) =>(e.country));
      // console.log(mapped.keys().sort());
      // const mapped2 = d3c.map(allRecords, (e) =>(e.resilience_stage));
      // console.log(mapped2.keys().sort());
    });

    reader.readAsText(file);

  } catch (error) {
    console.log("Error uploading file: ", error);
  }
}

async function seedResourceTypes() {
  await API.graphql(graphqlOperation(mutations.createResourceType, {
    input: {
      name: 'Scientific Articles'
    }
  }));
  await API.graphql(graphqlOperation(mutations.createResourceType, {
    input: {
      name: 'Industry Report'
    }
  }));
  await API.graphql(graphqlOperation(mutations.createResourceType, {
    input: {
      name: 'Online Resource'
    }
  }));

}

async function seedReselienceStages() {
  await API.graphql(graphqlOperation(mutations.createResilienceStage, {
    input: {
      title: 'All'
    }
  }));
  await API.graphql(graphqlOperation(mutations.createResilienceStage, {
    input: {
      title: 'Mitigation/Prevention'
    }
  }));
  await API.graphql(graphqlOperation(mutations.createResilienceStage, {
    input: {
      title: 'Recovery'
    }
  }));
  await API.graphql(graphqlOperation(mutations.createResilienceStage, {
    input: {
      title: 'Preparedness'
    }
  }));
  await API.graphql(graphqlOperation(mutations.createResilienceStage, {
    input: {
      title: 'Response'
    }
  }));

}


const seedAll = async function (){ // eslint-disable-line no-unused-vars
  await seedResourceTypes();
  await seedReselienceStages();
}

function CSVSeeder() {
  
  const handleInit = useCallback(async function () {
    try{
    // const delResp= await API.graphql(graphqlOperation(deleteResourceType, {input: {id: '590f9435-8cef-4111-9ccd-3f0dd74bbd01' }}));
    // console.log(delResp);
    const allResTypes = (await API.graphql(graphqlOperation(quereis.listResourceTypes))).data.listResourceTypes.items;
    const allResStages = (await API.graphql(graphqlOperation(quereis.listResilienceStages))).data.listResilienceStages.items;
    console.log(allResTypes,allResStages);
    //console.log(allRecords);
    console.log((await API.graphql(graphqlOperation(quereis.getResource,{id:'ec26d93f-207e-495b-b4e7-0819a9edcfc0'}))).data.getResource);
    console.log(await API.graphql(graphqlOperation(mutations.updateResource,{input: {id: 'ec26d93f-207e-495b-b4e7-0819a9edcfc0', resourceTypeResourcesId: '6a3d3a30-257c-46de-a4eb-abc91d0ad565' }})));
    console.log((await API.graphql(graphqlOperation(quereis.getResource,{id:'ec26d93f-207e-495b-b4e7-0819a9edcfc0'}))).data.getResource);
    // for (const rec of allRecords){
    //   const dbInput={
    //     litrev_id: rec.litrev_id,
    //     rating: rec.rating ? Number((rec.rating.match(/\d+/g))[0]) : 0,
    //     author: rec.author.trim(),
    //     authoryear: Number(rec.authoryear),
    //     title: rec.title,
    //     url: rec.url,
    //     object_key: '',
    //     abstract: rec.abstract,
    //     country: rec.country,
    //     theme: rec.theme,
    //     comments: rec.comments,
    //     research_question_issue: rec.research_question_issue,
    //     findings: rec.findings,
    //     recommendations: rec.recommendations,
    //     flooding_events_types: rec.flooding_events_types,
    //     potential_solution_extent_applied: rec.potential_solution_extent_applied,
    //     railway_adapt: rec.railway_adapt,
    //     contemporary_challenges: rec.contemporary_challenges,
    //     flooding_model_info: rec.flooding_model_info,
    //     future_trends_planning: rec.future_trends_planning,
    //     causal_factors: rec.causal_factors,
    //     resilienceStageResourcesId: (allResStages.find((st)=>(st.title == rec.resilience_stage )))?.id,
    //     resourceTypeResourcesId: (allResTypes.find((rs)=>(rs.name == 'Industry Report' )))?.id,
    //   };
    //   // console.log(rec);
    //   // console.log(dbInput);    
    //   // await API.graphql(graphqlOperation(mutations.createResource, {
    //   //   input: dbInput
    //   // }));
    //   // console.log(dbInput.litrev_id, ' created.');
    // }
    }
    catch (err)
    {
      console.log(err);
    }

  },[]);

  return (  
    <div>
    <input type="file" onChange={onChange} accept='.csv' />
    <Button onClick={handleInit}> Initilize</Button>
    </div> 
  );
}

export default CSVSeeder;
