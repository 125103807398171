import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Rating from '@mui/material/Rating';
import Grid from '@mui/material/Grid';
// import { Document } from 'react-pdf';
import LaunchIcon from '@mui/icons-material/Launch';
import { pdfjs } from 'react-pdf';
import ResourceAddEdit from './ResourceAddEdit';
import { API, graphqlOperation } from 'aws-amplify';
import * as quereis from './graphql/queries';
import * as mutations from './graphql/mutations';
import DialogActions from '@mui/material/DialogActions';
//import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

const fieldTitles = {
    "litrev_id": "ID",
    "rating": "Rating",
    "author": "Author",
    "authoryear": "Year",
    "title": "Title",
    "abstract": "Abstract",
    "country": "Country",
    "theme": "Theme",
    "resilience_stage": "Resilience Stage",
    "comments": "Comments",
    "research_question_issue": "Research Question/Issue",
    "findings": "Findings, Discussion, Conclusions",
    "recommendations": "Recommendations for further research",
    "flooding_events_types": "What types of flooding events are described. Describe case studies,  location, date",
    "potential_solution_extent_applied": "Potential solution? And to what context was it applied?",
    "railway_adapt": "What do we know about how railways adapt to flooding events?",
    "contemporary_challenges": "Contemporary challenges (re. flooding) that are listed",
    "flooding_model_info": "What flooding models exist? If so what type and what does the model do/is it for?",
    "future_trends_planning": "Future trends or planning for flooding events",
    "causal_factors": "Causal Factors (\"effect\", \"affect\")",
    "url": "Link"
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// const isURLToPDF = (urlstring) => (urlstring && (urlstring.search(/\.pdf/i) > 0))

const FiledListItem = ({ heading, value }) => {
    return (
        value ? (<ListItem >
            <ListItemText primary={heading} secondary={value} />
        </ListItem>) : null
    )
}

const resourceTagsByResourceId2 = /* GraphQL */ `
  query ResourceTagsByResourceId(
    $resourceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResourceTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceTagsByResourceId(
      resourceId: $resourceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resourceId
        tagId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const deleteResourceTags2 = /* GraphQL */ `
  mutation DeleteResourceTags(
    $input: DeleteResourceTagsInput!
    $condition: ModelResourceTagsConditionInput
  ) {
    deleteResourceTags(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;


export default function ResourceDetail({ open, setOpen, resourceObject, setCurrentResource, setAllResources, allTags, setAllTags }) {

    const [editMode, setEditMode] = React.useState(false);
    const [createMode, setCreateMode] = React.useState(false);
    const [allResStages, setAllResStages] = React.useState([]);
    const [allResTypes, setAllResTypes] = React.useState([]);


    React.useEffect(() => {
        async function load() {
            try {
                const res = await API.graphql(graphqlOperation(quereis.listResilienceStages, {
                    limit: 50
                }));
                const res2 = await API.graphql(graphqlOperation(quereis.listResourceTypes, {
                    limit: 50
                }));
                console.log(res, res2);
                setAllResStages(res.data.listResilienceStages.items);
                setAllResTypes(res2.data.listResourceTypes.items)
            }
            catch (err) {
                console.log(err);
            }
        }
        load();
    }, []);


    React.useEffect(() => {
         setCreateMode((!resourceObject.id) ? true : false);
    }, [resourceObject]);

    const handleClose = () => {
        setOpen(false);
        setEditMode(false);
    };

    const handleUpdate = React.useCallback(async (updatedObject, updatedTags) => {
        try {
            console.log('handleUpdate', updatedTags);
            // get and remove current tags:

            let allCurResTags = [], nt;
            do {
                const curTags = await API.graphql(graphqlOperation(resourceTagsByResourceId2, {
                    resourceId: updatedObject.id,
                    limit: 50,
                    nextToken: nt
                }));
                console.log(curTags);
                allCurResTags = allCurResTags.concat(curTags.data.resourceTagsByResourceId.items);
                nt = curTags.data.resourceTagsByResourceId.nextToken;
            } while (nt);

            for (const rtg of allCurResTags)
            {
                console.log('removing ', rtg);
                const resp = await API.graphql(graphqlOperation(deleteResourceTags2, {
                    input: {
                        id: rtg.id
                    }
                }));
                console.log(resp);
            }

            //attach new tags

            for (const tags of updatedTags)
            {
                console.log('Adding ', tags);
                const resp = await API.graphql(graphqlOperation(mutations.createResourceTags, {
                    input: {
                        resourceId: updatedObject.id,
                        tagId: tags.id
                    }
                }));
                console.log(resp);
            }


            const result = await API.graphql(graphqlOperation(mutations.updateResource, {
                input: {
                    id: updatedObject.id,
                    litrev_id: updatedObject.litrev_id || undefined,
                    rating: updatedObject.rating,
                    author: updatedObject.author,
                    authoryear: parseInt(updatedObject.authoryear),
                    title: updatedObject.title,
                    url: updatedObject.url,
                    // object_key: String
                    abstract: updatedObject.abstract,
                    country: updatedObject.country,
                    theme: updatedObject.theme,
                    comments: updatedObject.comments,
                    research_question_issue: updatedObject.research_question_issue,
                    findings: updatedObject.findings,
                    recommendations: updatedObject.recommendations,
                    flooding_events_types: updatedObject.flooding_events_types,
                    potential_solution_extent_applied: updatedObject.potential_solution_extent_applied,
                    railway_adapt: updatedObject.railway_adapt,
                    contemporary_challenges: updatedObject.contemporary_challenges,
                    flooding_model_info: updatedObject.flooding_model_info,
                    future_trends_planning: updatedObject.future_trends_planning,
                    causal_factors: updatedObject.causal_factors,
                    resilienceStageResourcesId: updatedObject.resilienceStageResourcesId,
                    resourceTypeResourcesId: updatedObject.resourceTypeResourcesId
                }
            }));
            console.log(result);
            const updatedResource = result.data.updateResource;
            setCurrentResource(updatedResource);
            setAllResources((prevAllResources) => (prevAllResources.map((res)=> ((res.id === updatedResource.id ) ? updatedResource : res))));

        }
        catch (err) {
            window.alert('An error occured: Unauthorized. Make sure you have relevant admin permission.');
            console.log(err);
        }
        setEditMode(false);
    }, [setCurrentResource, setAllResources]);


    const handleCreate = React.useCallback(async (newResourceObject, createdTags) => {
        try {
            const result = await API.graphql(graphqlOperation(mutations.createResource, {
                input: {
                    litrev_id: newResourceObject.litrev_id,
                    rating: newResourceObject.rating,
                    author: newResourceObject.author,
                    authoryear: parseInt(newResourceObject.authoryear),
                    title: newResourceObject.title,
                    url: newResourceObject.url,
                    // object_key: String
                    abstract: newResourceObject.abstract,
                    country: newResourceObject.country,
                    theme: newResourceObject.theme,
                    comments: newResourceObject.comments,
                    research_question_issue: newResourceObject.research_question_issue,
                    findings: newResourceObject.findings,
                    recommendations: newResourceObject.recommendations,
                    flooding_events_types: newResourceObject.flooding_events_types,
                    potential_solution_extent_applied: newResourceObject.potential_solution_extent_applied,
                    railway_adapt: newResourceObject.railway_adapt,
                    contemporary_challenges: newResourceObject.contemporary_challenges,
                    flooding_model_info: newResourceObject.flooding_model_info,
                    future_trends_planning: newResourceObject.future_trends_planning,
                    causal_factors: newResourceObject.causal_factors,
                    resilienceStageResourcesId: newResourceObject.resilienceStageResourcesId,
                    resourceTypeResourcesId: newResourceObject.resourceTypeResourcesId
                }
            }));
            console.log(result);
            const createdResource = result.data.createResource;


            //attach new tags

            for (const tags of createdTags) {
                const resp = await API.graphql(graphqlOperation(mutations.createResourceTags, {
                    input: {
                        resourceId: createdResource.id,
                        tagId: tags.id
                    }
                }));
                console.log(resp);
            }


            setAllResources((prevAllResources) => ([createdResource, ...prevAllResources]));
            setCreateMode(false);
            setOpen(false);

        }
        catch (err) {
            window.alert('An error occured: Unauthorized. Make sure you have relevant admin permission.');
            console.log(err);
        }
        setEditMode(false);
    }, [setAllResources, setOpen]);

    const handleDelete = React.useCallback(async () => {
        try {
            if (!window.confirm('Are you sure you want to delete this resource?'))
              return;
            const result = await API.graphql(graphqlOperation(mutations.deleteResource, {
                input: {
                    id: resourceObject.id,
                }
            }));
            console.log(result);
            const deletedResult = result.data.deleteResource;
            setAllResources((prevAllResources) => (prevAllResources.filter((res)=> (res.id !== deletedResult.id))));
            setOpen(false);

        }
        catch (err) {
            window.alert('An error occured: Unauthorized. Make sure you have relevant admin permission.');
            console.log(err);
        }
        setEditMode(false);
    }, [resourceObject, setAllResources,setOpen]);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {resourceObject.title}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleClose}>
                        Close
                    </Button>
                </Toolbar>
            </AppBar>
            {((editMode || createMode) ? <ResourceAddEdit key={resourceObject.id} resourceObject={resourceObject} allResStages={allResStages} allResTypes={allResTypes} handleUpdate={handleUpdate} handleCreate={handleCreate} allTags={allTags} setAllTags={setAllTags} handleClose={handleClose} /> : <Grid container spacing={0.5}>
                <Grid item md={12} mx={1}>
                    <List>
                        <ListItem >
                            <ListItemText primary={fieldTitles.rating} secondary={<Rating name="read-only" value={resourceObject.rating} readOnly />} />
                        </ListItem>
                        <FiledListItem heading={fieldTitles.author} value={resourceObject.author} />
                        <FiledListItem heading={fieldTitles.authoryear} value={resourceObject.authoryear} />
                        <FiledListItem heading={fieldTitles.country} value={resourceObject.country} />
                        <Divider />
                        <FiledListItem heading={fieldTitles.abstract} value={resourceObject.abstract} />
                        <ListItem>
                            <ListItemText primary={'Tags'} secondary={
                            <Stack direction="row" spacing={1} mt={1} flexWrap='wrap' >
                            {(resourceObject.tags?.items?.map((rtg) => (allTags.find((t) => (t.id === rtg.tagId))) || { id: rtg.tagId, label: '[DELETED]' }) || []).map((tg) => (<Chip label={tg.label} color="secondary" size='medium' />))}
                        </Stack>
                            }  />

                        </ListItem>
                        <FiledListItem heading={fieldTitles.theme} value={resourceObject.theme} />
                        <FiledListItem heading={fieldTitles.resilience_stage} value={resourceObject.resilience_stage?.title} />
                        <FiledListItem heading={fieldTitles.comments} value={resourceObject.comments} />
                        <FiledListItem heading={fieldTitles.research_question_issue} value={resourceObject.research_question_issue} />
                        <FiledListItem heading={fieldTitles.findings} value={resourceObject.findings} />
                        <FiledListItem heading={fieldTitles.recommendations} value={resourceObject.recommendations} />
                        <FiledListItem heading={fieldTitles.flooding_events_types} value={resourceObject.flooding_events_types} />
                        <FiledListItem heading={fieldTitles.potential_solution_extent_applied} value={resourceObject.potential_solution_extent_applied} />
                        <FiledListItem heading={fieldTitles.railway_adapt} value={resourceObject.railway_adapt} />
                        <FiledListItem heading={fieldTitles.contemporary_challenges} value={resourceObject.contemporary_challenges} />
                        <FiledListItem heading={fieldTitles.flooding_model_info} value={resourceObject.flooding_model_info} />
                        <FiledListItem heading={fieldTitles.future_trends_planning} value={resourceObject.future_trends_planning} />
                        <FiledListItem heading={fieldTitles.causal_factors} value={resourceObject.causal_factors} />
                        <FiledListItem heading={fieldTitles.url} value={resourceObject.url} />
                    </List>
                    {resourceObject.url && <Button variant='contained' endIcon={<LaunchIcon />} href={resourceObject.url} target='_blank'>
                        visit resource
                    </Button>}


                </Grid>

                {/* {resourceObject.url && <Grid item container md={2} justifyContent="center" flexDirection='row' alignItems='center'>
                    <Document file={resourceObject.url} /> 
                    <Button variant='contained' endIcon={<LaunchIcon />} href={resourceObject.url} target='_blank'>
                        visit resource
                    </Button>
                </Grid>} */}

            </Grid>)}
            <DialogActions>
                {!editMode && !createMode && <><Button variant='contained' onClick={() => setEditMode(true)} color='warning'  >
                    Edit
                </Button>
                    <Button variant='contained' onClick={handleDelete} color='error'  >
                        Delete
                    </Button>
                </>}

            </DialogActions>


        </Dialog>
    );
}