import { useState, useCallback } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Rating from '@mui/material/Rating';
import Grid from '@mui/material/Grid';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import TagSelector from './TagSelector';




const fieldTitles = {
    "litrev_id": "ID",
    "rating": "Rating",
    "author": "Author",
    "authoryear": "Year",
    "title": "Title",
    "abstract": "Abstract",
    "country": "Country",
    "theme": "Theme",
    "resilience_stage": "Resilience Stage",
    "resource_type": "Resource Type",
    "comments": "Comments",
    "research_question_issue": "Research Question/Issue",
    "findings": "Findings, Discussion, Conclusions",
    "recommendations": "Recommendations for further research",
    "flooding_events_types": "What types of flooding events are described. Describe case studies,  location, date",
    "potential_solution_extent_applied": "Potential solution? And to what context was it applied?",
    "railway_adapt": "What do we know about how railways adapt to flooding events?",
    "contemporary_challenges": "Contemporary challenges (re. flooding) that are listed",
    "flooding_model_info": "What flooding models exist? If so what type and what does the model do/is it for?",
    "future_trends_planning": "Future trends or planning for flooding events",
    "causal_factors": "Causal Factors (\"effect\", \"affect\")",
    "url": "Link"
};



// const isURLToPDF = (urlstring) => (urlstring && (urlstring.search(/\.pdf/i) > 0))

// const FiledListItem = ({ heading, value }) => {
//     return (
//         value ? (<ListItem >
//             <ListItemText primary={heading} secondary={value} />
//         </ListItem>) : null
//     )
// }

export default function ResourceAddEdit({ resourceObject, allResStages, allResTypes, handleUpdate, handleCreate, allTags, setAllTags, handleClose }) {
    const [loading, setLoading] = useState(false);
    const [newObject, setNewObject] = useState(resourceObject);
    const [currentTags, setCurrentTags] = useState(resourceObject.tags?.items?.map((rtg) => (allTags.find((t) => (t.id === rtg.tagId) )) || {id: rtg.tagId, label:'[DELETED]' } ) || []);



    const handleSave = useCallback(async function () {
        console.log(newObject);
        setLoading(true);
        if (newObject.id)
            await handleUpdate(newObject, currentTags);
        else
            await handleCreate(newObject, currentTags);
        setLoading(false);

    }, [newObject, currentTags, handleUpdate, handleCreate]);


    return (newObject && <Grid container spacing={2} my={1} px={2}>
        <Grid item sm={12}>
            <Typography component="legend">{fieldTitles.rating}</Typography>
            <Rating
                value={newObject.rating || null}
                style={{ marginTop: '1rem' }}
                onChange={(ev,newVal) => setNewObject((prevObject) => ({ ...prevObject, rating: newVal }))}
            />

            <FormControl fullWidth style={{ marginTop: '1rem' }}>
                <InputLabel id="res-type-select-label">{fieldTitles.resource_type}</InputLabel>
                <Select
                    labelId="res-type-select-label"
                    id="res-type-select"
                    value={newObject.resourceTypeResourcesId}
                    label="Reource Type"
                    onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, resourceTypeResourcesId: ev.target.value }))}
                >
                    {allResTypes.map((type) => (<MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>))}
                </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '1rem' }}>
                <InputLabel id="res-stage-select-label">{fieldTitles.resilience_stage}</InputLabel>
                <Select
                    labelId="res-stage-select-label"
                    id="res-stage-select"
                    value={newObject.resilienceStageResourcesId}
                    label="Resilience Stage"
                    onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, resilienceStageResourcesId: ev.target.value }))}
                >
                    {allResStages.map((stage) => (<MenuItem key={stage.id} value={stage.id}>{stage.title}</MenuItem>))}
                </Select>
            </FormControl>

            <TextField
                label={fieldTitles.title}
                value={newObject.title}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, title: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />

            <TextField
                label={fieldTitles.author}
                value={newObject.author}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, author: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />

            <TextField
                label={fieldTitles.authoryear}
                value={newObject.authoryear}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, authoryear: ev.target.value }))}
                style={{ marginTop: '1rem' }}
                type='number'
            />
            <TextField
                label={fieldTitles.country}
                value={newObject.country}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, country: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />
            <TextField
                label={fieldTitles.abstract}
                value={newObject.abstract}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, abstract: ev.target.value }))}
                style={{ marginTop: '1rem' }}
                multiline
                rows={5}
            />
            
            <TagSelector key={newObject.id} naturalText={newObject.abstract} currentTags={currentTags} setCurrentTags={setCurrentTags} allTags={allTags} setAllTags={setAllTags} />

            <TextField
                label={fieldTitles.theme}
                value={newObject.theme}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, theme: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />
            <TextField
                label={fieldTitles.comments}
                value={newObject.comments}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, comments: ev.target.value }))}
                style={{ marginTop: '1rem' }}
                multiline
                rows={3}
            />
            <TextField
                label={fieldTitles.research_question_issue}
                value={newObject.research_question_issue}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, research_question_issue: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />
            <TextField
                label={fieldTitles.findings}
                value={newObject.findings}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, findings: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />
            <TextField
                label={fieldTitles.recommendations}
                value={newObject.recommendations}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, recommendations: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />
            <TextField
                label={fieldTitles.flooding_events_types}
                value={newObject.flooding_events_types}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, flooding_events_types: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />
            <TextField
                label={fieldTitles.potential_solution_extent_applied}
                value={newObject.potential_solution_extent_applied}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, potential_solution_extent_applied: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />
            <TextField
                label={fieldTitles.railway_adapt}
                value={newObject.railway_adapt}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, railway_adapt: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />
            <TextField
                label={fieldTitles.contemporary_challenges}
                value={newObject.contemporary_challenges}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, contemporary_challenges: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />
            <TextField
                label={fieldTitles.flooding_model_info}
                value={newObject.flooding_model_info}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, flooding_model_info: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />
            <TextField
                label={fieldTitles.future_trends_planning}
                value={newObject.future_trends_planning}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, future_trends_planning: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />
            <TextField
                label={fieldTitles.causal_factors}
                value={newObject.causal_factors}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, causal_factors: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />

            <TextField
                label={fieldTitles.url}
                value={newObject.url || ''}
                fullWidth
                onChange={(ev) => setNewObject((prevObject) => ({ ...prevObject, url: ev.target.value }))}
                style={{ marginTop: '1rem' }}
            />


            <LoadingButton loading={loading}  variant='contained'  endIcon={<SaveOutlinedIcon />} onClick={handleSave} style={{ marginTop: '1rem' }}>
                Save
            </LoadingButton>

            <LoadingButton loading={loading}  variant='outlined'   onClick={handleClose} style={{ marginTop: '1rem' , marginLeft:'0.5rem' }}>
                Cancel
            </LoadingButton>

        </Grid>

    </Grid>
    );
}