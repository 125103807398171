import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import FloodIcon from '@mui/icons-material/Flood';
import LaunchIcon from '@mui/icons-material/Launch';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as quereis from './graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import Chip from '@mui/material/Chip';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import ResourceDetail from './ResourceDetail';
import './Home.css';
//import { RecentActorsRounded } from '@mui/icons-material';
import {debounce} from 'lodash';
import LinearProgress from '@mui/material/LinearProgress';
//import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';

function LinearColor() {
  return (
    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2} mt={0.5}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack>
  );
}

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://floodingtoolbox.smartinfrastructuredashboard.org/">
                Flooding Toolbox
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Home({ signOut, user }) {
    const [allResources, setAllResources] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [viewModalOpen, setViewModalOpen] = React.useState(false);
    const [currentResource, setCurrentResource] = React.useState({});
    const [loading,setLoading] = React.useState(false);
    const [allTags, setAllTags] = React.useState([]);
    const [selectedTag, setSelectedTag] = React.useState('');
    const [searchDisabled, setSearchDisabled] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

  
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    React.useEffect(() => {
        async function load() {
            try {
                let allDBTags = [], nextToken; 
                do {
                    const res = await API.graphql(graphqlOperation(quereis.listTags, { 
                        limit: 50, 
                        nextToken: nextToken
                     }));
                     console.log(res);
                    allDBTags = allDBTags.concat(res.data.listTags.items);
                    nextToken=res.data.listTags.nextToken;
                } while (nextToken);
                console.log(allDBTags);
    
                setAllTags(allDBTags);
            }
            catch (err) {
                console.log(err);
            }
        }
        load();
    }, []);
    


    const loadCallback = React.useMemo(
        () => debounce(async (searchQuery,selTag) => {
            try {
                setLoading(true);
                const queryFilter = (searchQuery.length > 1) ? ({
                    or: [
                        { title: { contains: searchQuery } },
                        { abstract: { contains: searchQuery } },
                        { author: { contains: searchQuery } },
                        { research_question_issue: { contains: searchQuery } },
                        { findings: { contains: searchQuery } },
                        { railway_adapt: { contains: searchQuery } },
                        { country: { eq: capitalizeFirstLetter(searchQuery) } }
                    ]
                }) : ({
                    // rating: { ge: 5 }
                });



                if (selTag)
                {
                    let allRes = [], nextToken; 
                    do {
                    const res = await API.graphql(graphqlOperation(quereis.resourceTagsByTagId, { 
                        tagId: selTag,
                        limit: 50, 
                     }));
                    allRes = allRes.concat(res.data.resourceTagsByTagId.items.map((itm)=>(itm.resource)));
                    } while (nextToken);
                    console.log(allRes);
                    setAllResources(allRes);
                } else {
                    let allRes = [], nextToken; 
                    do {
                        const res = await API.graphql(graphqlOperation(quereis.listResources, { 
                            limit: 50, 
                            filter: queryFilter,
                            nextToken: nextToken
                         }));
                         console.log(res);
                        allRes = allRes.concat(res.data.listResources.items);
                        nextToken=res.data.listResources.nextToken;
                    } while (nextToken);
                    console.log(allRes.length);
                    setAllResources(allRes);

                }

            }
            catch (err) {
                console.log(err);
            }
            setLoading(false);
        }, 500),
        []
      );

    React.useEffect(() => {
        loadCallback(searchTerm,selectedTag);
    }, [searchTerm,loadCallback,selectedTag]);


    const viewResourceClick= React.useCallback(async (resourceID) => {
        const res = await API.graphql(graphqlOperation(quereis.getResource, { id: resourceID  }));
        console.log(res);
        setCurrentResource(res.data.getResource);
        setViewModalOpen(true);
    },[]);

    const createResourceClick= React.useCallback(async () => {
        setCurrentResource({});
        setViewModalOpen(true);
    },[]);

    const handleTagChanged = (event) => {
        setSelectedTag(event.target.value);
        setSearchDisabled(!!event.target.value);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <AppBar position="relative">
                <Toolbar>
                    <FloodIcon sx={{ mr: 2 }} />
                    <Typography variant="h6" color="inherit" noWrap flexGrow={1}>
                        Flooding Toolbox
                    </Typography>
                    <Typography variant="h6" color="inherit">
                      Logged in as {user.attributes.given_name} {user.attributes.family_name} <b>{user.signInUserSession.idToken?.payload['cognito:groups']?.includes('Admins') ? '[ADMIN]' :''}</b>
                    </Typography>
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem>Logged in as&nbsp;<b>{user.attributes.email}</b></MenuItem>
                                <MenuItem onClick={signOut}>Sign out</MenuItem>
                            </Menu>
                        </div>
                </Toolbar>
            </AppBar>
            <main>
                {/* Hero unit */}
                <ResourceDetail open={viewModalOpen} setOpen={setViewModalOpen} resourceObject={currentResource} setCurrentResource={setCurrentResource} setAllResources={setAllResources} allTags={allTags} setAllTags={setAllTags}/>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 2,
                    }}
                >
                    <Container maxWidth="lg">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            ACRI Flooding Toolbox
                        </Typography>
                        {/* developed  is a tool that */}
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            The Flooding Toolbox  supports decision makers in the Australasian Rail industry to identify relevant sources of information around flooding management.
                        </Typography>
                        <Typography variant="h6" align="center" color="text.secondary" paragraph>
                        Disclaimer: This tool is provided for development and trial purposes only. Your use of the site is solely at your own risk.
                        </Typography>
                        {/* <Stack
                            sx={{ pt: 4 }}
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                        >
                            <Button variant="contained">Main call to action</Button>
                            <Button variant="outlined">Secondary action</Button>
                        </Stack> */}
                        <TextField
                            id="filled-search"
                            label="Search within resource title, abstract, research questions, findings etc."
                            type="search"
                            value={searchTerm}
                            onChange={(ev) => setSearchTerm(ev.target.value)}
                            fullWidth
                            disabled={searchDisabled}
                        />
                        <FormControl fullWidth style={{marginTop:'1rem'}}>
                          {/* <InputLabel id="tags-label">Filter by a Tag</InputLabel> */}
                        <Select
                            labelId="tags-label"
                            id="tags"
                            value={selectedTag}
                            // label="tags"
                            displayEmpty
                            onChange={handleTagChanged}
                        >
                            <MenuItem value={''} selected><em>None</em></MenuItem>
                            {allTags.map((tg) => (<MenuItem value={tg.id}>{tg.label}</MenuItem>))}
                        </Select>
                        <FormHelperText>Filter by a Tag</FormHelperText>
                        </FormControl>
                    </Container>
                </Box>
                <Container sx={{ py: 1 }} maxWidth="lg">
                    {/* End hero unit */}
                    <Button  variant='outlined' color='success' onClick={createResourceClick}>Add new resource</Button>
                    {loading && <LinearColor/>}
                    <Grid container spacing={4} mt={0.5}>
                        {allResources.map((res) => (
                            <Grid item key={res.id} xs={12} sm={6} md={4}>
                                <Card
                                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                                >
                                    <CardMedia
                                        component="div"
                                        sx={{
                                            // 16:9
                                            pt: '56.25%',
                                        }}
                                        image="https://source.unsplash.com/random?wallpapers"
                                    />
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        {res.rating > 0 && <Rating name="read-only" value={res.rating} readOnly />}
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {res.title}
                                        </Typography>
                                        <Typography className='clipped-abstract' >
                                            {res.abstract}
                                        </Typography>
                                        {res.url && <Button variant='outlined' endIcon={<LaunchIcon />} href={res.url} target='_blank'>
                                            Launch
                                        </Button>}
                                        <Stack direction="row" spacing={1} mt={1} flexWrap='wrap' >
                                            {res.type && <Chip label={res.type?.name} color="primary" size='small' /> }
                                            {res.country && <Chip label={res.country} color="error" size='small' />}
                                        </Stack>
                                        <Stack direction="row" spacing={1} mt={1}>
                                            {res.resilience_stage && <Chip label={res.resilience_stage?.title} color="success" size='small' />}
                                        </Stack>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" variant='outlined' onClick={() => viewResourceClick(res.id)}>View</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
            {/* Footer */}
            <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
                {/* <Typography variant="h6" align="center" gutterBottom>
                    Footer
                </Typography> */}
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    Flooding Toolbox by the University of Wollongong and the Australasian Centre for Rail Innovation (ACRI)
                </Typography>
                <Copyright />
            </Box>
            {/* End footer */}
        </ThemeProvider>
    );
}